// generated with @7nohe/openapi-react-query-codegen@1.6.1
import { ConfigurationService } from "../requests/services.gen";

import { UseQueryResult } from "@tanstack/react-query";

export type ConfigurationServiceGetCustomApiConfigurationPublicShoppingCartPositionsCountDefaultResponse =
  Awaited<
    ReturnType<
      typeof ConfigurationService.getCustomApiConfigurationPublicShoppingCartPositionsCount
    >
  >;
export type ConfigurationServiceGetCustomApiConfigurationPublicShoppingCartPositionsCountQueryResult<
  TData = ConfigurationServiceGetCustomApiConfigurationPublicShoppingCartPositionsCountDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useConfigurationServiceGetCustomApiConfigurationPublicShoppingCartPositionsCountKey =
  "ConfigurationServiceGetCustomApiConfigurationPublicShoppingCartPositionsCount";
export const UseConfigurationServiceGetCustomApiConfigurationPublicShoppingCartPositionsCountKeyFn =
  (
    {
      localeId,
      userSessionId,
    }: {
      localeId?: string;
      userSessionId?: string;
    } = {},
    queryKey?: Array<unknown>,
  ) => [
    useConfigurationServiceGetCustomApiConfigurationPublicShoppingCartPositionsCountKey,
    ...(queryKey ?? [{ localeId, userSessionId }]),
  ];
export type ConfigurationServiceGetCustomApiConfigurationPublicShoppingCartByQuoteIdExtendedDataDefaultResponse =
  Awaited<
    ReturnType<
      typeof ConfigurationService.getCustomApiConfigurationPublicShoppingCartByQuoteIdExtendedData
    >
  >;
export type ConfigurationServiceGetCustomApiConfigurationPublicShoppingCartByQuoteIdExtendedDataQueryResult<
  TData = ConfigurationServiceGetCustomApiConfigurationPublicShoppingCartByQuoteIdExtendedDataDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useConfigurationServiceGetCustomApiConfigurationPublicShoppingCartByQuoteIdExtendedDataKey =
  "ConfigurationServiceGetCustomApiConfigurationPublicShoppingCartByQuoteIdExtendedData";
export const UseConfigurationServiceGetCustomApiConfigurationPublicShoppingCartByQuoteIdExtendedDataKeyFn =
  (
    {
      quoteId,
      userSessionId,
    }: {
      quoteId: string;
      userSessionId?: string;
    },
    queryKey?: Array<unknown>,
  ) => [
    useConfigurationServiceGetCustomApiConfigurationPublicShoppingCartByQuoteIdExtendedDataKey,
    ...(queryKey ?? [{ quoteId, userSessionId }]),
  ];
export type ConfigurationServiceGetCustomApiConfigurationPublicShoppingCartLineItemByLineItemIdExtendedDataDefaultResponse =
  Awaited<
    ReturnType<
      typeof ConfigurationService.getCustomApiConfigurationPublicShoppingCartLineItemByLineItemIdExtendedData
    >
  >;
export type ConfigurationServiceGetCustomApiConfigurationPublicShoppingCartLineItemByLineItemIdExtendedDataQueryResult<
  TData = ConfigurationServiceGetCustomApiConfigurationPublicShoppingCartLineItemByLineItemIdExtendedDataDefaultResponse,
  TError = unknown,
> = UseQueryResult<TData, TError>;
export const useConfigurationServiceGetCustomApiConfigurationPublicShoppingCartLineItemByLineItemIdExtendedDataKey =
  "ConfigurationServiceGetCustomApiConfigurationPublicShoppingCartLineItemByLineItemIdExtendedData";
export const UseConfigurationServiceGetCustomApiConfigurationPublicShoppingCartLineItemByLineItemIdExtendedDataKeyFn =
  (
    {
      lineItemId,
      userSessionId,
    }: {
      lineItemId: string;
      userSessionId?: string;
    },
    queryKey?: Array<unknown>,
  ) => [
    useConfigurationServiceGetCustomApiConfigurationPublicShoppingCartLineItemByLineItemIdExtendedDataKey,
    ...(queryKey ?? [{ lineItemId, userSessionId }]),
  ];
export type ConfigurationServicePostCustomApiConfigurationPublicStartConfiguratorMutationResult =
  Awaited<
    ReturnType<
      typeof ConfigurationService.postCustomApiConfigurationPublicStartConfigurator
    >
  >;
export type ConfigurationServicePostCustomApiConfigurationPublicShoppingCartConfigurationsMutationResult =
  Awaited<
    ReturnType<
      typeof ConfigurationService.postCustomApiConfigurationPublicShoppingCartConfigurations
    >
  >;
export type ConfigurationServicePostCustomApiConfigurationPublicShoppingCartConfigurationsEditMutationResult =
  Awaited<
    ReturnType<
      typeof ConfigurationService.postCustomApiConfigurationPublicShoppingCartConfigurationsEdit
    >
  >;
export type ConfigurationServicePostCustomApiConfigurationPublicShoppingCartShowMutationResult =
  Awaited<
    ReturnType<
      typeof ConfigurationService.postCustomApiConfigurationPublicShoppingCartShow
    >
  >;
export type ConfigurationServicePostCustomApiConfigurationPublicShoppingCartProductsMutationResult =
  Awaited<
    ReturnType<
      typeof ConfigurationService.postCustomApiConfigurationPublicShoppingCartProducts
    >
  >;
export type ConfigurationServicePostCustomApiConfigurationPublicShoppingCartByQuoteIdExcelMutationResult =
  Awaited<
    ReturnType<
      typeof ConfigurationService.postCustomApiConfigurationPublicShoppingCartByQuoteIdExcel
    >
  >;
export type ConfigurationServicePostCustomApiConfigurationPublicShoppingCartQuoteRequestMailMutationResult =
  Awaited<
    ReturnType<
      typeof ConfigurationService.postCustomApiConfigurationPublicShoppingCartQuoteRequestMail
    >
  >;
export type ConfigurationServicePutCustomApiConfigurationPublicShoppingCartConfigurationsByLineItemIdMutationResult =
  Awaited<
    ReturnType<
      typeof ConfigurationService.putCustomApiConfigurationPublicShoppingCartConfigurationsByLineItemId
    >
  >;
export type ConfigurationServicePutCustomApiConfigurationPublicShoppingCartByQuoteIdExtendedDataMutationResult =
  Awaited<
    ReturnType<
      typeof ConfigurationService.putCustomApiConfigurationPublicShoppingCartByQuoteIdExtendedData
    >
  >;
export type ConfigurationServicePutCustomApiConfigurationPublicShoppingCartLineItemByLineItemIdExtendedDataMutationResult =
  Awaited<
    ReturnType<
      typeof ConfigurationService.putCustomApiConfigurationPublicShoppingCartLineItemByLineItemIdExtendedData
    >
  >;
