import { LineItem } from "../LineItem/LineItem";
import { SubItems } from "../SubItems/SubItems";

import { useState } from "react";

import { VMLineItems, VMQuotes } from "@4pace-cpq/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";

export function LineItemAccordion({
  lineItem,
  quoteId,
}: {
  lineItem: VMLineItems;
  quoteId: VMQuotes["id"];
}): JSX.Element {
  const hasSubItems = Boolean(
    lineItem.subItems && lineItem.subItems.length > 0,
  );
  const [isExpanded, setIsExpanded] = useState(hasSubItems);
  return (
    <Accordion expanded={isExpanded}>
      <AccordionSummary
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        sx={{
          cursor: "default",
          "&.MuiAccordionSummary-root:hover:not(.Mui-disabled)": {
            cursor: "default",
          },
          "&.Mui-focusVisible": {
            backgroundColor: "transparent",
          },
        }}
        expandIcon={
          hasSubItems ? (
            <ExpandMoreIcon
              sx={{
                cursor: "pointer",
              }}
              onClick={() => setIsExpanded((prev) => !prev)} // Only toggle when clicking the icon
              style={{ cursor: "pointer" }}
            />
          ) : (
            <ExpandMoreIcon sx={{ color: "transparent" }} onClick={() => {}} />
          )
        }
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
          }}
        >
          <LineItem item={lineItem} quoteId={quoteId} />
        </Box>
      </AccordionSummary>
      {hasSubItems && (
        <AccordionDetails
          sx={{
            padding: "0.5rem 2rem 2rem",
          }}
        >
          <SubItems lineItem={lineItem} />
        </AccordionDetails>
      )}
    </Accordion>
  );
}
