import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { CartItem } from "@4pace-cpq/core/dist/types/views/CartView/CartView";
import { TextField, debounce } from "@mui/material";
import Box from "@mui/material/Box";
import { useQueryClient } from "@tanstack/react-query";

import { LabeledText } from "@/cpq/overrides/LabeledText/LabeledText";
import { useConfigurationServiceGetCustomApiConfigurationPublicShoppingCartByQuoteIdExtendedDataKey } from "@/openapi/api-cpq/queries/common";
import {
  useConfigurationServiceGetCustomApiConfigurationPublicShoppingCartByQuoteIdExtendedData,
  useConfigurationServicePutCustomApiConfigurationPublicShoppingCartByQuoteIdExtendedData,
} from "@/openapi/api-cpq/queries/queries";

export default function AdditionalQuoteFields({
  item,
}: {
  item: CartItem;
}): JSX.Element {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const userSessionId = window.sessionStorage.getItem("userSessionId");
  const { data: extendedData } =
    useConfigurationServiceGetCustomApiConfigurationPublicShoppingCartByQuoteIdExtendedData(
      { quoteId: item.id },
    );

  const { mutate: setExtendedData } =
    useConfigurationServicePutCustomApiConfigurationPublicShoppingCartByQuoteIdExtendedData(
      {},
    );

  const [projectName, setProjectName] = useState("");
  const [projectLocation, setProjectLocation] = useState("");

  useEffect(() => {
    if (extendedData?.projectLocation) {
      setProjectLocation(extendedData.projectLocation);
    }
    if (extendedData?.projectName) {
      setProjectName(extendedData.projectName);
    }
  }, [extendedData?.projectLocation, extendedData?.projectName]);

  const sendProjectNameChange = useCallback(
    (value: string) => {
      if (userSessionId) {
        setExtendedData(
          {
            quoteId: item.id,
            requestBody: {
              projectName: value,
              projectLocation: projectLocation,
              userSessionId: userSessionId,
            },
          },
          {
            onSuccess: () => {
              queryClient.invalidateQueries({
                queryKey: [
                  useConfigurationServiceGetCustomApiConfigurationPublicShoppingCartByQuoteIdExtendedDataKey,
                ],
              });
            },
          },
        );
      }
    },
    [item.id, projectLocation, userSessionId, setExtendedData, queryClient],
  );

  const debouncedProjectNameChange = useMemo(() => {
    return debounce(sendProjectNameChange, 500);
  }, [sendProjectNameChange]);

  function handleProjectNameChange(value: string): void {
    setProjectName(value);
    debouncedProjectNameChange(value);
  }

  const sendProjectLocationChange = useCallback(
    (value: string) => {
      if (userSessionId) {
        setExtendedData(
          {
            quoteId: item.id,
            requestBody: {
              projectName: projectName,
              projectLocation: value,
              userSessionId: userSessionId,
            },
          },
          {
            onSuccess: () => {
              queryClient.invalidateQueries({
                queryKey: [
                  useConfigurationServiceGetCustomApiConfigurationPublicShoppingCartByQuoteIdExtendedDataKey,
                ],
              });
            },
          },
        );
      }
    },
    [item.id, projectName, userSessionId, setExtendedData, queryClient],
  );

  const debouncedProjectLocationChange = useMemo(() => {
    return debounce(sendProjectLocationChange, 500);
  }, [sendProjectLocationChange]);

  function handleProjectLocationChange(value: string): void {
    setProjectLocation(value);
    debouncedProjectLocationChange(value);
  }

  return (
    <Box sx={{ display: "flex", gap: "1rem" }}>
      <LabeledText
        label={
          t("regiolux:label.additionalQuoteFields.projectDescription") + "*"
        }
        text={
          <TextField
            value={projectName}
            onChange={(e) => {
              handleProjectNameChange(e.target.value);
            }}
            fullWidth
          />
        }
      />
      <LabeledText
        label={t("regiolux:label.additionalQuoteFields.projectLocation") + "*"}
        text={
          <TextField
            value={projectLocation}
            onChange={(e) => {
              handleProjectLocationChange(e.target.value);
            }}
            fullWidth
          />
        }
      />
    </Box>
  );
}
