import { useTranslation } from "react-i18next";

import { VMLineItems } from "@4pace-cpq/core";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import { SubItem } from "./SubItem";

export function SubItems({ lineItem }: { lineItem: VMLineItems }): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h5">{t("CPQ:lineItems.bom.label")}</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("CPQ:item.property.label.partId")}</TableCell>
            <TableCell>{t("CPQ:item.property.label.name")}</TableCell>
            <TableCell>{t("CPQ:item.property.label.description")}</TableCell>
            <TableCell>{t("CPQ:item.property.label.quantity")}</TableCell>
            <TableCell>
              {t("regiolux:subItem.property.label.listPrice")}
            </TableCell>
            <TableCell>
              {t("regiolux:subItem.property.label.totalList")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lineItem?.subItems?.map((subItem) => (
            <SubItem
              key={subItem.id}
              item={subItem}
              lineItemQuantity={lineItem.quantity ?? 1}
            />
          ))}
        </TableBody>
      </Table>
      <Box
        sx={{
          justifySelf: "flex-end",
        }}
      ></Box>
    </>
  );
}
