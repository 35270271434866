import { ReactNode } from "react";

import { Tooltip } from "@mui/material";
import Box from "@mui/material/Box";

export function Description({
  text,
  maxWidth,
}: {
  text: ReactNode;
  maxWidth?: string;
}): JSX.Element {
  return (
    <Tooltip title={text}>
      <Box
        sx={{
          maxWidth: maxWidth,
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {text}
      </Box>
    </Tooltip>
  );
}
