export default {
  regiolux: {
    showDebugDrawer: "Show Debug Drawer",
    "previous.configuration": "Zurück",
    "next.configuration": "Weiter",
    "reset.configuration": "Auswahl zurücksetzen",
    "reset.subConfiguration": "Gruppe zurücksetzen",
    "bom.header": "Stückliste",
    "bom.sum": "Summe Positionen",
    "add.XAS_GERAETETRAEGER": "weitere Geräteträger hinzufügen",
    "add.XAS_FUNKTIONSMODUL": "Funktionsmodul hinzufügen (Optional)",
    "add.XAS_TRAGSCHIENE": "Tragschiene hinzufügen",
    "add.show_BOM": "Konfigurationsergebnis anzeigen",
    addToShoppingCart: "Zum Warenkorb hinzufügen",
    saveConfiguration: "Konfiguration speichern",
    "sidebar.header": "SRT KONFIGURATOR",
    "dialog.requestQuote.open": "Angebot anfragen",
    "dialog.requestQuote.wholesale":
      "Über welchen Großhandel soll das Angebot abgegeben werden?",
    "dialog.requestQuote.message": "Nachricht",
    "dialog.requestQuote.email": "Ihre E-Mail-Adresse",
    "dialog.requestQuote.title": "Anrede",
    "dialog.requestQuote.firstName": "Vorname",
    "dialog.requestQuote.lastName": "Nachname",
    "dialog.requestQuote.phoneNumber": "Telefonnummer",
    "dialog.requestQuote.optionalDetails": "freiwillige Angaben",
    "dialog.requestQuote.street": "Straße / Nr.",
    "dialog.requestQuote.postcode": "Ort / PLZ",
    "dialog.requestQuote.company": "Firma",
    "dialog.requestQuote.position": "Position",
    "dialog.requestQuote.industry": "Branche",
    "dialog.requestQuote.country": "Land",
    "dialog.requestQuote.mobileNumber": "Mobil",
    "dialog.requestQuote.text.title": "Warenkorb",
    "dialog.requestQuote.description": "Bitte füllen Sie das Formular aus",
    "dialog.requestQuote.text.cancel": "Zurück",
    "dialog.requestQuote.text.confirm": "Angebot anfragen",
    "dialog.requestQuote.dataPrivacy.title": "Datenschutz",
    "dialog.requestQuote.dataPrivacy.text.start":
      "Mit der Bestellung erkläre ich mich mit der Speicherung und Nutzung der Daten gemäß der",
    "dialog.requestQuote.dataPrivacy.text.end":
      "der Firma Regiolux GmbH einverstanden.",
    "dialog.requestQuote.dataPrivacy.link.text": "Datenschutzerklärung",
    "dialog.requestQuote.dataPrivacy.link.url":
      "https://www.regiolux.de/de/datenschutz",
    "dialog.requestQuote.emailMarketingConsent.title": "Information",
    "tooltip.toConfigure": "Konfigurieren",
    "title.configurationFinished":
      "Die Konfiguration wurde erfolgreich abgeschlossen.",
    "subItem.property.label.listPrice": "Listenpreis",
    "subItem.property.label.totalList": "Summe",
    "item.property.label.totalList": "Summe",
    "toast.requestQuote.error": "Angebot konnte nicht angefragt werden",
    "label.additionalQuoteFields.projectDescription": "Projektbezeichnung",
    "label.additionalQuoteFields.projectLocation": "Projektort",
    "item.property.label.note": "Hinweis",
    "button.downloadShoppingCart.label": "Warenkorb herunterladen",
  },
};
