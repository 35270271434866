import { BillOfMaterialsData } from "../BillOfMaterials/BillOfMaterialsData";
import { useBomContext } from "../BillOfMaterials/BomContext";

import Box from "@mui/material/Box";
import { SubstructureContent } from "@plansoft-configuration-renderer/components";

import { useSubstructureStandaloneContentCtx } from "@/renderer/SubstructureStandaloneContentCtx";

export function StandaloneSubstructureContent(): JSX.Element {
  const { selectedSubstructure } = useSubstructureStandaloneContentCtx();
  const { isBomOpen } = useBomContext();
  return (
    <Box>
      {selectedSubstructure && (
        <SubstructureContent substructure={selectedSubstructure} />
      )}
      {isBomOpen && <BillOfMaterialsData />}
    </Box>
  );
}
