import { useState } from "react";
import { useTranslation } from "react-i18next";

import { toast } from "@4pace-cpq/core";
import Box from "@mui/material/Box";

import { iframeId } from "@/Router";
import CustomDialog from "@/components/CustomDialog/CustomDialog";
import CustomDialogActions from "@/components/CustomDialog/CustomDialogActions";
import { RegioluxMessageIcon } from "@/icons/RegioluxIcons";
import {
  useConfigurationServiceGetCustomApiConfigurationPublicShoppingCartByQuoteIdExtendedData,
  useConfigurationServicePostCustomApiConfigurationPublicShoppingCartQuoteRequestMail,
} from "@/openapi/api-cpq/queries/queries";

import { UserInformationForm } from "./UserInformationForm";

export function RequestQuoteDialog({
  quoteId,
  isEmpty = true,
}: {
  quoteId: string;
  isEmpty?: boolean;
}): JSX.Element {
  const { t } = useTranslation();

  const { data: extendedData } =
    useConfigurationServiceGetCustomApiConfigurationPublicShoppingCartByQuoteIdExtendedData(
      { quoteId },
    );

  const projectName = extendedData?.projectName;
  const projectLocation = extendedData?.projectLocation;

  const [wholesale, setWholesale] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [street, setStreet] = useState("");
  const [postcode, setPostcode] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [position, setPosition] = useState("");
  const [industry, setIndustry] = useState("");
  const [country, setCountry] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [dataPrivacy, setDataPrivacy] = useState(false);

  const userInformationProps = {
    wholesale,
    message,
    email,
    title,
    firstName,
    lastName,
    phoneNumber,
    street,
    postcode,
    companyName,
    position,
    industry,
    country,
    mobileNumber,
    dataPrivacy,
    setWholesale,
    setMessage,
    setEmail,
    setTitle,
    setFirstName,
    setLastName,
    setPhoneNumber,
    setStreet,
    setPostcode,
    setCompanyName,
    setPosition,
    setIndustry,
    setCountry,
    setMobileNumber,
    setDataPrivacy,
  };

  function resetInputFields(): void {
    setWholesale("");
    setMessage("");
    setEmail("");
    setTitle("");
    setFirstName("");
    setLastName("");
    setPhoneNumber("");
    setStreet("");
    setPostcode("");
    setCompanyName("");
    setPosition("");
    setIndustry("");
    setCountry("");
    setMobileNumber("");
    setDataPrivacy(false);
  }

  const { mutate: requestQuote } =
    useConfigurationServicePostCustomApiConfigurationPublicShoppingCartQuoteRequestMail();

  const userSessionId = window.sessionStorage.getItem("userSessionId");

  function onConfirm(closeDialog: () => void): void {
    if (userSessionId) {
      requestQuote(
        {
          requestBody: {
            userSessionId,
            title,
            firstName,
            lastName,
            position,
            phoneNumber,
            mobileNumber,
            companyName,
            industry,
            street,
            city: postcode,
            country,
            message,
            wholesale,
            emailAddress: email,
          },
        },
        {
          onSuccess: () => {
            const postMessage = {
              iframeId: iframeId,
              eventName: "clearShoppingCart",
            };
            window.parent.postMessage(postMessage, "*");
            resetInputFields();
            closeDialog();
          },
          onError: (error) => {
            console.log(error);
            toast.error(t("regiolux:toast.requestQuote.error"));
          },
        },
      );
    } else {
      console.error("User session id not found");
    }
  }

  const re = /\S+@\S+\.\S+/;

  return (
    <Box>
      <CustomDialog
        dialog={{
          title: t("regiolux:dialog.requestQuote.text.title"),
          description: t("regiolux:dialog.requestQuote.description"),
          content: <UserInformationForm {...userInformationProps} />,
          actions: ({ closeDialog }) => (
            <CustomDialogActions
              textCancel={t("regiolux:dialog.requestQuote.text.cancel")}
              textConfirm={t("regiolux:dialog.requestQuote.text.confirm")}
              confirmDisabled={
                email === "" ||
                !re.test(email) ||
                !dataPrivacy ||
                !firstName ||
                !lastName
              }
              onCancel={() => {
                closeDialog();
              }}
              onConfirm={() => {
                onConfirm(closeDialog);
              }}
            />
          ),
        }}
        buttonProps={{
          disabled: projectName === "" || projectLocation === "" || isEmpty,
          children: t("regiolux:dialog.requestQuote.open"),
          startIcon: <RegioluxMessageIcon />,
        }}
      />
    </Box>
  );
}
