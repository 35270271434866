import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

interface UserStandardDetailsProps {
  wholesale: string;
  message: string;
  email: string;
  title: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  setWholesale: (wholesale: string) => void;
  setMessage: (message: string) => void;
  setEmail: (email: string) => void;
  setTitle: (title: string) => void;
  setFirstName: (firstName: string) => void;
  setLastName: (lastName: string) => void;
  setPhoneNumber: (phoneNumber: string) => void;
}

export default function UserStandardDetails({
  wholesale,
  message,
  email,
  title,
  firstName,
  lastName,
  phoneNumber,
  setWholesale,
  setMessage,
  setEmail,
  setTitle,
  setFirstName,
  setLastName,
  setPhoneNumber,
}: UserStandardDetailsProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Box display={"flex"} gap={"1rem"} marginBlock={"1rem"} flexWrap={"wrap"}>
      <Box display={"flex"} flexDirection={"column"} flexBasis={"100%"}>
        <Typography>{t("regiolux:dialog.requestQuote.wholesale")}</Typography>
        <TextField
          type="email"
          value={wholesale}
          onChange={(event) => setWholesale(event.target.value)}
        />
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        flex={1}
        flexBasis={"100%"}
      >
        <Typography>{t("regiolux:dialog.requestQuote.message")}</Typography>
        <TextField
          id="outlined-multiline-static"
          multiline
          rows={5}
          value={message}
          onChange={(event) => setMessage(event.target.value)}
        />
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        flexBasis={"calc(50% - 0.5rem)"}
      >
        <Typography>{t("regiolux:dialog.requestQuote.email")}*</Typography>
        <TextField
          type="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        flexBasis={"calc(50% - 0.5rem)"}
      >
        <Typography>{t("regiolux:dialog.requestQuote.title")}</Typography>
        <TextField
          value={title}
          onChange={(event) => setTitle(event.target.value)}
        />
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        flexBasis={"calc(50% - 0.5rem)"}
      >
        <Typography>{t("regiolux:dialog.requestQuote.firstName")}*</Typography>
        <TextField
          value={firstName}
          onChange={(event) => setFirstName(event.target.value)}
        />
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        flexBasis={"calc(50% - 0.5rem)"}
      >
        <Typography>{t("regiolux:dialog.requestQuote.lastName")}*</Typography>
        <TextField
          value={lastName}
          onChange={(event) => setLastName(event.target.value)}
        />
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        flexBasis={"calc(50% - 0.5rem)"}
      >
        <Typography>{t("regiolux:dialog.requestQuote.phoneNumber")}</Typography>
        <TextField
          value={phoneNumber}
          onChange={(event) => setPhoneNumber(event.target.value)}
        />
      </Box>
    </Box>
  );
}
