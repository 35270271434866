import { VMQuotes } from "@4pace-cpq/core";

export function tryParseOutputData<T>(data: Partial<T>): T {
  if ("output" in data && typeof data["output"] === "string") {
    try {
      const result = JSON.parse(data["output"]);
      if (result && typeof result === "object") {
        data["output"] = result;
      }
    } catch (error) {
      console.error("Error parsing output data:", error);
    }
  }
  return data as T;
}

export const contentHeightResizeObserver = new ResizeObserver(() => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const iframeId = urlParams.get("iframeId");

  const height = document.documentElement.getBoundingClientRect().height;

  const postMessage = {
    iframeId: iframeId,
    eventName: "changeIframeHeight",
    height: height + 10,
  };

  window.parent.postMessage(postMessage, "*");
});

export async function exportQuoteToExcel(
  quoteId: VMQuotes["id"],
  requestBody?: {
    [key: string]: string | null;
  },
): Promise<void> {
  try {
    const response = await fetch(
      `/custom/api/Configuration/public/shoppingCart/${quoteId}/Excel`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      },
    );
    if (!response.ok) {
      throw new Error(
        "There is something wrong with the response: " + response.statusText,
      );
    }

    // Extract filename from Content-Disposition header
    let fileName = `Warenkorb.xlsx`; // Default file name
    const contentDisposition = response.headers.get("Content-Disposition");
    console.log(contentDisposition);

    if (contentDisposition) {
      fileName =
        contentDisposition
          ?.split(";")
          ?.find((n) => n.includes("filename="))
          ?.replace(/filename=|"|'/g, "")
          .trim() || fileName;
    }

    // Create a Blob from the response
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);

    // Create a temporary anchor element for downloading
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName; // Set the file name from Content-Disposition or default
    document.body.appendChild(a);
    a.click();

    // Clean up and remove the anchor element after download
    a.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error:", error); // Handle errors
  }
}
