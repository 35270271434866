// generated with @7nohe/openapi-react-query-codegen@1.6.1
import { ConfigurationService } from "../requests/services.gen";
import {
  ConfiguratorBody,
  ConfiguratorEdit,
  EmailRequest,
  LineItemBody,
  LineItemExtendedDataBody,
  LineItemUpdateBody,
  ProductRequestBody,
  QuoteExtendedDataBody,
  ShoppingCartExcelBody,
  ShoppingCartShow,
} from "../requests/types.gen";

import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";

import * as Common from "./common";

export const useConfigurationServiceGetCustomApiConfigurationPublicShoppingCartPositionsCount =
  <
    TData = Common.ConfigurationServiceGetCustomApiConfigurationPublicShoppingCartPositionsCountDefaultResponse,
    TError = unknown,
    TQueryKey extends Array<unknown> = unknown[],
  >(
    {
      localeId,
      userSessionId,
    }: {
      localeId?: string;
      userSessionId?: string;
    } = {},
    queryKey?: TQueryKey,
    options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
  ) =>
    useQuery<TData, TError>({
      queryKey:
        Common.UseConfigurationServiceGetCustomApiConfigurationPublicShoppingCartPositionsCountKeyFn(
          { localeId, userSessionId },
          queryKey,
        ),
      queryFn: () =>
        ConfigurationService.getCustomApiConfigurationPublicShoppingCartPositionsCount(
          { localeId, userSessionId },
        ) as TData,
      ...options,
    });
export const useConfigurationServiceGetCustomApiConfigurationPublicShoppingCartByQuoteIdExtendedData =
  <
    TData = Common.ConfigurationServiceGetCustomApiConfigurationPublicShoppingCartByQuoteIdExtendedDataDefaultResponse,
    TError = unknown,
    TQueryKey extends Array<unknown> = unknown[],
  >(
    {
      quoteId,
      userSessionId,
    }: {
      quoteId: string;
      userSessionId?: string;
    },
    queryKey?: TQueryKey,
    options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
  ) =>
    useQuery<TData, TError>({
      queryKey:
        Common.UseConfigurationServiceGetCustomApiConfigurationPublicShoppingCartByQuoteIdExtendedDataKeyFn(
          { quoteId, userSessionId },
          queryKey,
        ),
      queryFn: () =>
        ConfigurationService.getCustomApiConfigurationPublicShoppingCartByQuoteIdExtendedData(
          { quoteId, userSessionId },
        ) as TData,
      ...options,
    });
export const useConfigurationServiceGetCustomApiConfigurationPublicShoppingCartLineItemByLineItemIdExtendedData =
  <
    TData = Common.ConfigurationServiceGetCustomApiConfigurationPublicShoppingCartLineItemByLineItemIdExtendedDataDefaultResponse,
    TError = unknown,
    TQueryKey extends Array<unknown> = unknown[],
  >(
    {
      lineItemId,
      userSessionId,
    }: {
      lineItemId: string;
      userSessionId?: string;
    },
    queryKey?: TQueryKey,
    options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">,
  ) =>
    useQuery<TData, TError>({
      queryKey:
        Common.UseConfigurationServiceGetCustomApiConfigurationPublicShoppingCartLineItemByLineItemIdExtendedDataKeyFn(
          { lineItemId, userSessionId },
          queryKey,
        ),
      queryFn: () =>
        ConfigurationService.getCustomApiConfigurationPublicShoppingCartLineItemByLineItemIdExtendedData(
          { lineItemId, userSessionId },
        ) as TData,
      ...options,
    });
export const useConfigurationServicePostCustomApiConfigurationPublicStartConfigurator =
  <
    TData = Common.ConfigurationServicePostCustomApiConfigurationPublicStartConfiguratorMutationResult,
    TError = unknown,
    TContext = unknown,
  >(
    options?: Omit<
      UseMutationOptions<
        TData,
        TError,
        {
          requestBody?: ConfiguratorBody;
        },
        TContext
      >,
      "mutationFn"
    >,
  ) =>
    useMutation<
      TData,
      TError,
      {
        requestBody?: ConfiguratorBody;
      },
      TContext
    >({
      mutationFn: ({ requestBody }) =>
        ConfigurationService.postCustomApiConfigurationPublicStartConfigurator({
          requestBody,
        }) as unknown as Promise<TData>,
      ...options,
    });
export const useConfigurationServicePostCustomApiConfigurationPublicShoppingCartConfigurations =
  <
    TData = Common.ConfigurationServicePostCustomApiConfigurationPublicShoppingCartConfigurationsMutationResult,
    TError = unknown,
    TContext = unknown,
  >(
    options?: Omit<
      UseMutationOptions<
        TData,
        TError,
        {
          requestBody?: LineItemBody;
        },
        TContext
      >,
      "mutationFn"
    >,
  ) =>
    useMutation<
      TData,
      TError,
      {
        requestBody?: LineItemBody;
      },
      TContext
    >({
      mutationFn: ({ requestBody }) =>
        ConfigurationService.postCustomApiConfigurationPublicShoppingCartConfigurations(
          { requestBody },
        ) as unknown as Promise<TData>,
      ...options,
    });
export const useConfigurationServicePostCustomApiConfigurationPublicShoppingCartConfigurationsEdit =
  <
    TData = Common.ConfigurationServicePostCustomApiConfigurationPublicShoppingCartConfigurationsEditMutationResult,
    TError = unknown,
    TContext = unknown,
  >(
    options?: Omit<
      UseMutationOptions<
        TData,
        TError,
        {
          requestBody?: ConfiguratorEdit;
        },
        TContext
      >,
      "mutationFn"
    >,
  ) =>
    useMutation<
      TData,
      TError,
      {
        requestBody?: ConfiguratorEdit;
      },
      TContext
    >({
      mutationFn: ({ requestBody }) =>
        ConfigurationService.postCustomApiConfigurationPublicShoppingCartConfigurationsEdit(
          { requestBody },
        ) as unknown as Promise<TData>,
      ...options,
    });
export const useConfigurationServicePostCustomApiConfigurationPublicShoppingCartShow =
  <
    TData = Common.ConfigurationServicePostCustomApiConfigurationPublicShoppingCartShowMutationResult,
    TError = unknown,
    TContext = unknown,
  >(
    options?: Omit<
      UseMutationOptions<
        TData,
        TError,
        {
          requestBody?: ShoppingCartShow;
        },
        TContext
      >,
      "mutationFn"
    >,
  ) =>
    useMutation<
      TData,
      TError,
      {
        requestBody?: ShoppingCartShow;
      },
      TContext
    >({
      mutationFn: ({ requestBody }) =>
        ConfigurationService.postCustomApiConfigurationPublicShoppingCartShow({
          requestBody,
        }) as unknown as Promise<TData>,
      ...options,
    });
export const useConfigurationServicePostCustomApiConfigurationPublicShoppingCartProducts =
  <
    TData = Common.ConfigurationServicePostCustomApiConfigurationPublicShoppingCartProductsMutationResult,
    TError = unknown,
    TContext = unknown,
  >(
    options?: Omit<
      UseMutationOptions<
        TData,
        TError,
        {
          requestBody?: ProductRequestBody;
        },
        TContext
      >,
      "mutationFn"
    >,
  ) =>
    useMutation<
      TData,
      TError,
      {
        requestBody?: ProductRequestBody;
      },
      TContext
    >({
      mutationFn: ({ requestBody }) =>
        ConfigurationService.postCustomApiConfigurationPublicShoppingCartProducts(
          { requestBody },
        ) as unknown as Promise<TData>,
      ...options,
    });
export const useConfigurationServicePostCustomApiConfigurationPublicShoppingCartByQuoteIdExcel =
  <
    TData = Common.ConfigurationServicePostCustomApiConfigurationPublicShoppingCartByQuoteIdExcelMutationResult,
    TError = unknown,
    TContext = unknown,
  >(
    options?: Omit<
      UseMutationOptions<
        TData,
        TError,
        {
          quoteId: string;
          requestBody?: ShoppingCartExcelBody;
        },
        TContext
      >,
      "mutationFn"
    >,
  ) =>
    useMutation<
      TData,
      TError,
      {
        quoteId: string;
        requestBody?: ShoppingCartExcelBody;
      },
      TContext
    >({
      mutationFn: ({ quoteId, requestBody }) =>
        ConfigurationService.postCustomApiConfigurationPublicShoppingCartByQuoteIdExcel(
          { quoteId, requestBody },
        ) as unknown as Promise<TData>,
      ...options,
    });
export const useConfigurationServicePostCustomApiConfigurationPublicShoppingCartQuoteRequestMail =
  <
    TData = Common.ConfigurationServicePostCustomApiConfigurationPublicShoppingCartQuoteRequestMailMutationResult,
    TError = unknown,
    TContext = unknown,
  >(
    options?: Omit<
      UseMutationOptions<
        TData,
        TError,
        {
          requestBody?: EmailRequest;
        },
        TContext
      >,
      "mutationFn"
    >,
  ) =>
    useMutation<
      TData,
      TError,
      {
        requestBody?: EmailRequest;
      },
      TContext
    >({
      mutationFn: ({ requestBody }) =>
        ConfigurationService.postCustomApiConfigurationPublicShoppingCartQuoteRequestMail(
          { requestBody },
        ) as unknown as Promise<TData>,
      ...options,
    });
export const useConfigurationServicePutCustomApiConfigurationPublicShoppingCartConfigurationsByLineItemId =
  <
    TData = Common.ConfigurationServicePutCustomApiConfigurationPublicShoppingCartConfigurationsByLineItemIdMutationResult,
    TError = unknown,
    TContext = unknown,
  >(
    options?: Omit<
      UseMutationOptions<
        TData,
        TError,
        {
          lineItemId: string;
          requestBody?: LineItemUpdateBody;
        },
        TContext
      >,
      "mutationFn"
    >,
  ) =>
    useMutation<
      TData,
      TError,
      {
        lineItemId: string;
        requestBody?: LineItemUpdateBody;
      },
      TContext
    >({
      mutationFn: ({ lineItemId, requestBody }) =>
        ConfigurationService.putCustomApiConfigurationPublicShoppingCartConfigurationsByLineItemId(
          { lineItemId, requestBody },
        ) as unknown as Promise<TData>,
      ...options,
    });
export const useConfigurationServicePutCustomApiConfigurationPublicShoppingCartByQuoteIdExtendedData =
  <
    TData = Common.ConfigurationServicePutCustomApiConfigurationPublicShoppingCartByQuoteIdExtendedDataMutationResult,
    TError = unknown,
    TContext = unknown,
  >(
    options?: Omit<
      UseMutationOptions<
        TData,
        TError,
        {
          quoteId: string;
          requestBody?: QuoteExtendedDataBody;
        },
        TContext
      >,
      "mutationFn"
    >,
  ) =>
    useMutation<
      TData,
      TError,
      {
        quoteId: string;
        requestBody?: QuoteExtendedDataBody;
      },
      TContext
    >({
      mutationFn: ({ quoteId, requestBody }) =>
        ConfigurationService.putCustomApiConfigurationPublicShoppingCartByQuoteIdExtendedData(
          { quoteId, requestBody },
        ) as unknown as Promise<TData>,
      ...options,
    });
export const useConfigurationServicePutCustomApiConfigurationPublicShoppingCartLineItemByLineItemIdExtendedData =
  <
    TData = Common.ConfigurationServicePutCustomApiConfigurationPublicShoppingCartLineItemByLineItemIdExtendedDataMutationResult,
    TError = unknown,
    TContext = unknown,
  >(
    options?: Omit<
      UseMutationOptions<
        TData,
        TError,
        {
          lineItemId: string;
          requestBody?: LineItemExtendedDataBody;
        },
        TContext
      >,
      "mutationFn"
    >,
  ) =>
    useMutation<
      TData,
      TError,
      {
        lineItemId: string;
        requestBody?: LineItemExtendedDataBody;
      },
      TContext
    >({
      mutationFn: ({ lineItemId, requestBody }) =>
        ConfigurationService.putCustomApiConfigurationPublicShoppingCartLineItemByLineItemIdExtendedData(
          { lineItemId, requestBody },
        ) as unknown as Promise<TData>,
      ...options,
    });
