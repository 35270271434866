import { useState } from "react";
import { useTranslation } from "react-i18next";

import { FormControlLabel, FormLabel } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";

import UserOptionalDetails from "./UserOptionalDetails";
import UserStandardDetails from "./UserStandardDetails";

interface UserInformationFormProps {
  wholesale: string;
  message: string;
  email: string;
  title: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  street: string;
  postcode: string;
  companyName: string;
  position: string;
  industry: string;
  country: string;
  mobileNumber: string;
  dataPrivacy: boolean;
  setWholesale: (wholesale: string) => void;
  setMessage: (message: string) => void;
  setEmail: (email: string) => void;
  setTitle: (title: string) => void;
  setFirstName: (firstName: string) => void;
  setLastName: (lastName: string) => void;
  setPhoneNumber: (phoneNumber: string) => void;
  setStreet: (street: string) => void;
  setPostcode: (postcode: string) => void;
  setCompanyName: (company: string) => void;
  setPosition: (position: string) => void;
  setIndustry: (industry: string) => void;
  setCountry: (country: string) => void;
  setMobileNumber: (mobileNumber: string) => void;
  setDataPrivacy: (dataPrivacy: boolean) => void;
}

export function UserInformationForm({
  wholesale,
  message,
  email,
  title,
  firstName,
  lastName,
  phoneNumber,
  street,
  postcode,
  companyName,
  position,
  industry,
  country,
  mobileNumber,
  dataPrivacy,
  setWholesale,
  setMessage,
  setEmail,
  setTitle,
  setFirstName,
  setLastName,
  setPhoneNumber,
  setStreet,
  setPostcode,
  setCompanyName,
  setPosition,
  setIndustry,
  setCountry,
  setMobileNumber,
  setDataPrivacy,
}: UserInformationFormProps): JSX.Element {
  const { t } = useTranslation();

  const [optionalDetailsIsOpen, setOptionalDetailsIsOpen] = useState(false);

  return (
    <>
      <UserStandardDetails
        wholesale={wholesale}
        message={message}
        email={email}
        title={title}
        firstName={firstName}
        lastName={lastName}
        phoneNumber={phoneNumber}
        setWholesale={setWholesale}
        setMessage={setMessage}
        setEmail={setEmail}
        setTitle={setTitle}
        setFirstName={setFirstName}
        setLastName={setLastName}
        setPhoneNumber={setPhoneNumber}
      />
      <Box>
        <Button
          onClick={() => setOptionalDetailsIsOpen(!optionalDetailsIsOpen)}
        >
          {t("regiolux:dialog.requestQuote.optionalDetails")}
        </Button>
      </Box>
      {optionalDetailsIsOpen && (
        <UserOptionalDetails
          street={street}
          postcode={postcode}
          companyName={companyName}
          position={position}
          industry={industry}
          country={country}
          mobileNumber={mobileNumber}
          setStreet={setStreet}
          setPostcode={setPostcode}
          setCompanyName={setCompanyName}
          setPosition={setPosition}
          setIndustry={setIndustry}
          setCountry={setCountry}
          setMobileNumber={setMobileNumber}
        />
      )}
      <Box sx={{ marginTop: "1rem" }}>
        <FormLabel component="legend">
          {t("regiolux:dialog.requestQuote.dataPrivacy.title")}*
        </FormLabel>
        <FormControlLabel
          control={
            <Checkbox
              checked={dataPrivacy}
              onChange={() => setDataPrivacy(!dataPrivacy)}
            />
          }
          label={
            <Typography>
              {t("regiolux:dialog.requestQuote.dataPrivacy.text.start")}{" "}
              <a href={t("regiolux:dialog.requestQuote.dataPrivacy.link.url")}>
                {t("regiolux:dialog.requestQuote.dataPrivacy.link.text")}
              </a>{" "}
              {t("regiolux:dialog.requestQuote.dataPrivacy.text.end")}
            </Typography>
          }
        />
      </Box>
    </>
  );
}
