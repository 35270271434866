import { LabeledText } from "../LabeledText/LabeledText";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { VMLineItems } from "@4pace-cpq/core";
import { debounce } from "@mui/material";
import TextField from "@mui/material/TextField";

import {
  useConfigurationServiceGetCustomApiConfigurationPublicShoppingCartLineItemByLineItemIdExtendedData,
  useConfigurationServicePutCustomApiConfigurationPublicShoppingCartLineItemByLineItemIdExtendedData,
} from "@/openapi/api-cpq/queries/queries";

export default function LineItemCommentSection({
  item,
}: {
  item: VMLineItems;
}): JSX.Element {
  const { t } = useTranslation();

  const userSessionId = window.sessionStorage.getItem("userSessionId");

  const { data: extendedData } =
    useConfigurationServiceGetCustomApiConfigurationPublicShoppingCartLineItemByLineItemIdExtendedData(
      {
        lineItemId: item.id,
      },
    );

  const { mutate: setExtendedData } =
    useConfigurationServicePutCustomApiConfigurationPublicShoppingCartLineItemByLineItemIdExtendedData(
      {},
    );

  const [lineItemComment, setLineItemComment] = useState<string>("");

  useEffect(() => {
    if (extendedData?.comment) {
      setLineItemComment(extendedData.comment);
    }
  }, [extendedData?.comment]);

  const sendLineItemChange = useCallback(
    (value: string) => {
      if (userSessionId) {
        setExtendedData({
          lineItemId: item.id,
          requestBody: {
            comment: value,
            userSessionId: userSessionId,
          },
        });
      }
    },
    [item.id, setExtendedData, userSessionId],
  );

  const debouncedProjectLocationChange = useMemo(() => {
    return debounce(sendLineItemChange, 500);
  }, [sendLineItemChange]);

  function handleChange(value: string): void {
    setLineItemComment(value);
    debouncedProjectLocationChange(value);
  }

  return (
    <LabeledText
      sx={{
        width: "50%",
      }}
      label={t("regiolux:item.property.label.note")}
      text={
        <TextField
          value={lineItemComment}
          onChange={(e) => {
            handleChange(e.target.value);
          }}
          fullWidth
        />
      }
    />
  );
}
