import {
  Description,
  FormattedStaticCurrencyNumber,
  VMSubItems,
  useTranslatedProductDescription,
  useTranslatedProductName,
} from "@4pace-cpq/core";
import { TableCell, TableRow } from "@mui/material";

export function SubItem({
  item,
  lineItemQuantity,
}: {
  item: VMSubItems;
  lineItemQuantity: number;
}): JSX.Element {
  const translatedName = useTranslatedProductName(item);
  const translatedDescription = useTranslatedProductDescription(item);

  if (!item) {
    return <></>;
  }
  const currencyIdentifier = item.currency?.identifier;

  return (
    <TableRow>
      <TableCell
        sx={{
          width: "80px",
        }}
      >
        {item.product?.partId ?? "-"}
      </TableCell>
      <TableCell
        sx={{
          width: "200px",
        }}
      >
        {translatedName ?? "-"}
      </TableCell>
      <TableCell>
        {item.product && (
          <Description maxWidth="50ch" text={translatedDescription} />
        )}
      </TableCell>
      <TableCell>
        {item.quantity ? item.quantity * lineItemQuantity : null}
      </TableCell>
      <TableCell>
        <FormattedStaticCurrencyNumber
          value={item.listPrice}
          currencyIdentifier={currencyIdentifier}
        />
      </TableCell>
      <TableCell>
        <FormattedStaticCurrencyNumber
          value={item.totalList ? item.totalList * lineItemQuantity : null}
          currencyIdentifier={currencyIdentifier}
        />
      </TableCell>
    </TableRow>
  );
}
