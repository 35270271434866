import { BasicItemInfo } from "../BasicItemInfo/BasicItemInfo";
import { CloneItemIconButtonWrapper } from "../CloneItemIconButtonWrapper/CloneItemIconButtonWrapper";
import { CustomIndicatorConfigurableProductInLineItem } from "../CustomIndicatorConfigurableProductInLineItem/CustomIndicatorConfigurableProductInLineItem";
import { DeleteItemIconButtonWrapper } from "../DeleteRowMenuItemWrapper/DeleteRowMenuItemWrapper";
import { LabeledText } from "../LabeledText/LabeledText";
import LineItemCommentSection from "../LineItemCommentSection/LineItemCommentSection";
import { SumListPrice } from "../SumListPrice/SumListPrice";
import { TotalListPrice } from "../TotalListPrice/TotalListPrice";

import { useTranslation } from "react-i18next";

import {
  StandardComponents,
  VMLineItems,
  VMQuotes,
  isLineItem,
  isSubItem,
} from "@4pace-cpq/core";
import Box from "@mui/material/Box";

export function LineItem({
  item,
  quoteId,
}: {
  item: VMLineItems;
  quoteId: VMQuotes["id"];
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", flex: 1, gap: 2 }}>
      <LineItemCommentSection item={item} />
      <Box
        sx={{
          flex: 1,
          padding: "1rem",
          display: "flex",
          flexDirection: "row",
          gap: 2,
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <StandardComponents.LineItemUpperPlaceholder />
          <BasicItemInfo item={item} />
          <StandardComponents.LineItemLowerPlaceholder />
        </Box>

        <Box display="flex" gap={2} minWidth="450px">
          <LabeledText
            label={t("CPQ:item.property.label.quantity")}
            text={
              <StandardComponents.ConnectedQuantity
                value={item.quantity || 0}
                item={item}
              />
            }
          />

          <TotalListPrice item={item} />
          <SumListPrice item={item} />

          <Box display="flex" alignItems="baseline" gap={1} ml="auto">
            {quoteId &&
              (isLineItem(item) || isSubItem(item)) &&
              ((item.productId && item.product?.cfgObjectIdentifier) ||
                item.configuration) && (
                <CustomIndicatorConfigurableProductInLineItem
                  key="IndicatorConfigurableProductInLineItem"
                  quoteId={quoteId}
                  lineItemId={item.id}
                  productId={item.product?.identifier}
                  productTypeIdentifier={item.product?.productType?.identifier}
                  configurationId={item.configurationId || undefined}
                  isConfigurationComplete={
                    item.isConfigurationComplete || false
                  }
                  cfgObjectId={item.product?.cfgObjectIdentifier}
                />
              )}

            <CloneItemIconButtonWrapper item={item} />
            <DeleteItemIconButtonWrapper item={item} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
