import { Description } from "../Description/Description";
import { LabeledText } from "../LabeledText/LabeledText";

import { useTranslation } from "react-i18next";

import {
  StandardComponents,
  VMLineItems,
  VMSubItems,
  useTranslatedProductDescription,
  useTranslatedProductName,
} from "@4pace-cpq/core";
import Box from "@mui/material/Box";

export function BasicItemInfo({
  item,
}: {
  item: VMLineItems | VMSubItems;
}): JSX.Element {
  const { t } = useTranslation();
  const name = useTranslatedProductName(item);
  const description = useTranslatedProductDescription(item);

  return (
    <Box display="flex" gap={2}>
      <StandardComponents.ArticleNumber item={item} />

      <LabeledText
        sx={{ width: "300px" }}
        label={t("CPQ:item.property.label.name")}
        text={name ?? "-"}
      />

      {description && (
        <LabeledText
          label={t("CPQ:item.property.label.description")}
          text={<Description text={description} />}
        />
      )}
    </Box>
  );
}
