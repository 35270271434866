import { isQuote } from "@4pace-cpq/core";
import { CartItem } from "@4pace-cpq/core/dist/types/views/CartView/CartView";
import Box from "@mui/material/Box";

import { DownloadShoppingCartButton } from "@/renderer/controls/ShoppingCart/DownloadShoppingCartButton";
import { RequestQuoteDialog } from "@/renderer/controls/ShoppingCart/RequestQuoteDialog";

export function CustomItemGroupActionButtonsArea({
  item,
}: {
  item: CartItem;
}): JSX.Element {
  let shoppingCartIsEmpty = true;

  if (isQuote(item)) {
    item.itemGroups?.map((itemGroup) => {
      if (itemGroup.lineItems) {
        shoppingCartIsEmpty = false;
      }
    });
  }

  return (
    <Box
      sx={{
        display: "flex",
        gap: "1rem",
      }}
    >
      <RequestQuoteDialog quoteId={item.id} isEmpty={shoppingCartIsEmpty} />
      <DownloadShoppingCartButton
        quoteId={item.id}
        isEmpty={shoppingCartIsEmpty}
      />
    </Box>
  );
}
